import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { ButtonContent } from "../global/boton/ButtonContent";

function HeroSection() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <>
      <div className="pic-wrapper">
        <figure
          className="pic-1"
          style={{ background: `url("${rpdata?.gallery?.[5]}")` }}
        ></figure>
        <figure
          className="pic-2"
          style={{ background: `url("${rpdata?.gallery?.[9]}")` }}
        ></figure>
        <figure
          className="pic-3"
          style={{ background: `url("${rpdata?.gallery?.[10]}")` }}
        ></figure>
        <figure
          className="pic-4"
          style={{ background: `url("${rpdata?.gallery?.[12]}")` }}
        ></figure>
        <div className="z-[4] relative w-4/5 mx-auto pt-[100px] pb-[50px] flex md:flex-row flex-col md:pb-[150px] md:pt-[170px]">
          <div className="w-full md:w-[60%] flex flex-col justify-center md:justify-start items-center">
            <h1 className="text-white md:text-start text-center">
              {rpdata?.dbSlogan?.[0].slogan}
            </h1>
            <p className="text-white md:text-start text-center">{rpdata?.dbValues?.[1].description}</p>
            <div className="flex w-full justify-center md:block">
              <ButtonContent />
            </div>
          </div>
          <div className="md:w-1/2 w-full flex justify-center items-center">
            <img src="https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/Años%20de%20experiencia%2F16%20Años.png?alt=media&token=6065fdd9-ffa7-4d8c-9826-d08764f65a69"
              alt="yearsnone" 
              className="md:w-[300px] w-[180px] md:-mt-10 mt-5"/>
          </div>
        </div>
      </div>
    </>
  );
}

export default HeroSection;
